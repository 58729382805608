import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import { useAuth } from "../../hooks/useAuth";

const PrivateRoute = ({ children, ...rest }) => {
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const { user, login, logout } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
