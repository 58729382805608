import requests from "./httpService";

const ProductServices = {
  getAllProducts({ page, limit, category, title, price }, config) {
    const searchCategory = category !== null ? category : "";
    const searchTitle = title !== null ? title : "";
    const searchPrice = price !== null ? price : "";

    return requests.get(
      `/product?searchBillete=${searchTitle}&page=${page}&limit=${limit}`,
      config
    );

    // return requests.get(
    //   `/products?page=${page}&limit=${limit}&category=${searchCategory}&title=${searchTitle}&price=${searchPrice}`
    // );
  },

  getStockOutProducts() {
    return requests.get("/products/stock-out");
  },

  getProductById(id) {
    return requests.post(`/products/${id}`);
  },

  addProduct(body, config) {
    return requests.post("/product/", body, config);
  },

  addAllProducts(body) {
    return requests.post("/products/all", body);
  },

  updateProduct(body, config) {
    return requests.put(`/product/`, body, config);
  },

  updateStatus(id, body) {
    return requests.put(`/products/status/${id}`, body);
  },

  deleteProduct(id, config) {
    return requests.delete(`/product/${id}`, config);
  },

  generateExcel(config) {
    return requests.get(`/product/pdf/first/`, {
      ...config,
      responseType: "blob",
    });
  },
};

export default ProductServices;
