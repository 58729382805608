import React, { useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") || null)
  );

  const login = async (email, token) => {
    console.log("lohgin");
    const authenticatedUser = { email, token };

    if (authenticatedUser.token) {
      localStorage.setItem("user", JSON.stringify(authenticatedUser));
      setUser(authenticatedUser);
    }
  };

  const logout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  useEffect(() => {
    // Realiza la lógica de autenticación en tu aplicación aquí
    // const storedUser = JSON.parse(localStorage.getItem("user"));
    // if (storedUser) {
    //   console.log({ storedUser });
    //   setUser(storedUser);
    // }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
