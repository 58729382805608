import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: `http://localhost:3001/`,
  timeout: 500000,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   let adminInfo;
//   if (Cookies.get("adminInfo")) {
//     adminInfo = JSON.parse(Cookies.get("adminInfo"));
//   }

//   return {
//     ...config,
//     headers: {
//       authorization: adminInfo ? `Bearer ${adminInfo.token}` : null,
//     },
//   };
// });

const responseBody = (response) => response.data;

const requests = {
  get: (url, headers) => instance.get(url, headers).then(responseBody),

  post: (url, body, config) =>
    instance.post(url, body, config).then(responseBody),

  put: (url, body, config) =>
    instance.put(url, body, config).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, config) => instance.delete(url, config).then(responseBody),
};

export default requests;
