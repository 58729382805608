import React, { useState, createContext, useRef } from "react";

import { notifySuccess, notifyError } from "../utils/toast";
import ProductServices from "../services/ProductServices";
// create context
export const SidebarContext = createContext();
export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const resultsPerPage = 20;
  const [limitData, setLimitData] = useState(10);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  const [lang, setLang] = useState("");
  const [time, setTime] = useState("");
  const [sortedField, setSortedField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [zone, setZone] = useState("");
  const [status, setStatus] = useState("");
  const [source, setSource] = useState("");
  const [category, setCategory] = useState(null);

  const searchRef = useRef("");

  const numero_Agencia = useRef("");
  const numero_billete = useRef("");
  const serie = useRef("");
  const pagina = useRef("");

  const numero_AgenciaUpdate = useRef("");
  const numero_billeteUpdate = useRef("");
  const serieUpdate = useRef("");
  const paginaUpdate = useRef("");

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);

  const handleChangePage = (p) => {
    setCurrentPage(p);
  };

  const handleSubmitForAll = (user) => {
    console.log(
      numero_Agencia?.current?.value,
      pagina?.current?.value,
      numero_billete?.current?.value,
      serie?.current?.value
    );
    const productData = {
      Numero_Agencia: numero_Agencia.current.value,
      Pagina: pagina.current.value,
      Numero_Billete: numero_billete.current.value,
      Serie: serie.current.value,
    };

    let config = {
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    };

    ProductServices.addProduct(productData, config)
      .then((res) => {
        setIsUpdate(true);
        notifySuccess(res.message);
        resetInputs();
      })
      .catch((err) => console.log({ err }));
  };

  const handleUpdate = (id, user) => {
    let config = {
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    };
    const productData = {
      Numero_Agencia: numero_AgenciaUpdate.current.value,
      Pagina: paginaUpdate.current.value,
      Numero_Billete: numero_billeteUpdate.current.value,
      Serie: serieUpdate.current.value,
      Billetes_ID_PK: id,
    };

    ProductServices.updateProduct(productData, config)
      .then((res) => {
        setIsUpdate(true);
        notifySuccess(res.message);
        resetInputs();
      })
      .catch((err) => console.log({ err }));
  };

  const resetInputs = () => {
    numero_Agencia.current.value = "";
    pagina.current.value = "";
    numero_billete.current.value = "";
    serie.current.value = "";
  };

  // const value = useMemo(
  //   () => ({
  //     isSidebarOpen,
  //     toggleSidebar,
  //     closeSidebar,
  //     isDrawerOpen,
  //     toggleDrawer,
  //     closeDrawer,
  //     setIsDrawerOpen,
  //     isModalOpen,
  //     toggleModal,
  //     closeModal,
  //     isUpdate,
  //     setIsUpdate,
  //   }),

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isSidebarOpen, isDrawerOpen, isModalOpen, isUpdate]
  // );

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        closeBulkDrawer,
        isBulkDrawerOpen,
        toggleBulkDrawer,
        lang,
        setLang,
        currentPage,
        setCurrentPage,
        handleChangePage,
        searchText,
        setSearchText,
        category,
        setCategory,
        searchRef,
        handleSubmitForAll,
        status,
        setStatus,
        zone,
        setZone,
        time,
        setTime,
        sortedField,
        setSortedField,
        source,
        setSource,
        resultsPerPage,
        limitData,
        setLimitData,
        numero_Agencia,
        numero_billete,
        serie,
        pagina,
        handleUpdate,
        numero_AgenciaUpdate,
        numero_billeteUpdate,
        serieUpdate,
        paginaUpdate,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
